import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VideoPlayer from 'vue-video-player/src';
import 'video.js/dist/video-js.css'
import { Carousel, CarouselItem } from 'element-ui';

Vue.config.productionTip = false
Vue.use(VideoPlayer);
Vue.use(Carousel);
Vue.use(CarouselItem);

router.beforeEach((to, from, next) => {    
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})

new Vue({
  router,
  VideoPlayer,
  render: h => h(App)
}).$mount('#app')
