<template>
  <div class="BigData">
    <!--固定 头部 -->
    <div class="navigation-header navigation-header-absolute">
      <!-- logo -->
      <router-link to="/">
        <img src="../../assets/img/xyyylogo.png" alt="logo" />
      </router-link>
      <!-- 导航栏 -->
      <nav class="navigation-nav">
        <ul>
          <li>
            <router-link to="BusinessHall" class="home-a"
              >智慧营业厅</router-link
            >
          </li>
          <li>
            <router-link to="Metaverse" class="home-a">元宇宙</router-link>
          </li>
          <li>
            <router-link to="BigData" class="home-a home-text"
              >大数据</router-link
            >
          </li>
          <li>
            <router-link to="InternetTerminal" class="home-a"
              >互联网终端</router-link
            >
          </li>
          <li>
            <router-link to="AboutUs" class="home-a">关于翔云</router-link>
          </li>
        </ul>
      </nav>
    </div>
    <!--移动头部 -->
    <div
      class="navigation-header navigation-header-bg"
      :style="isShow ? `top:0px;opacity: 0.7;` : `top:-100px;opacity: 0.8;`"
    >
      <!-- logo -->
      <router-link to="/">
        <img src="../../assets/img/xyyylogo.png" alt="logo" />
      </router-link>
      <!-- 导航栏 -->
      <nav class="navigation-nav">
        <ul>
          <li>
            <router-link to="BusinessHall" class="home-a"
              >智慧营业厅</router-link
            >
          </li>
          <li>
            <router-link to="Metaverse" class="home-a">元宇宙</router-link>
          </li>
          <li>
            <router-link to="BigData" class="home-a home-text"
              >大数据</router-link
            >
          </li>
          <li>
            <router-link to="InternetTerminal" class="home-a"
              >互联网终端</router-link
            >
          </li>
          <li>
            <router-link to="AboutUs" class="home-a">关于翔云</router-link>
          </li>
        </ul>
      </nav>
    </div>
    <!-- 大数据大屏 -->
    <div class="BigData-screen">
      <img src="../../assets/BigData/BigData.png" alt="" />
      <div class="screen-text">
        <img src="../../assets/BigData/BigData_text.png" alt="" />
        <p>
          大数据平台是高效智能化数据处理工具集，注重数据治理、存储、建模及海量数据分析。面对不同的企业对象，利用来自外部整合、内部积累的数据进行安全、高效的数据协作，让用户在正确的时间访问正确的数据，全面释放数据价值，加速企业的数字化转型。
        </p>
      </div>
    </div>
    <!-- 选项卡展示部分 -->
    <div class="BigData-show">
      <!-- 平台架构及能力 -->
      <div class="BigData-box" v-show ="TabId === 0">
        <div class="show_title">
          <img src="../../assets/BigData/xitongpingtai-logo.png" alt="" />
          <p>平台架构及能力</p>
        </div>
        <div class="BigData-content">
          <div class="show-content">
            <div class="content-up">
              <h3>平台架构</h3>
              <p>针对数据采集、数据清洗、数据存储、数据分析挖掘、数据可视化、数据安全等各个层面，都提供了功能强大的数据处理工具，形成了模块化、过程化、标准化的大数据应用装配能力；</p>
            </div>
            <div class="content-down">
              <h3>平台能力</h3>
              <p>数据采集、元数据管理、数据编织、数据治理、数据可视化；</p>
            </div>
          </div>
          <div class="show-img">
            <img src="../../assets/BigData/tab_BJ.png" alt="">
          </div>
        </div>
      </div>
      <!-- 日志管理 -->
      <div class="BigData-box" v-show ="TabId === 1">
        <div class="show_title">
          <img src="../../assets/BigData/rizhi-logo.png" alt="" />
          <p>日志管理</p>
        </div>
        <div class="BigData-content">
          <div class="show-content">
            <p>日志管理平台为轻量、稳定、可扩展的技术架构，基于大数据技术与智能算法，实现 PB级多源、离散日志的采集、处理、存储与查询分析;</p>
            <p>统一的日志管理服务，让用户不用担心日志管理中的各种问题，专注挖掘日志的数据价值;</p>
          </div>
           <div class="show-img">
            <img src="../../assets/BigData/tab_BJ.png" alt="">
          </div>
        </div>
      </div>
      <!-- 客户画像 -->
      <div class="BigData-box" v-show ="TabId === 2">
        <div class="show_title">
          <img src="../../assets/BigData/yonghuhuaxiang-logo.png" alt="" />
          <p>客户画像</p>
        </div>
        <div class="BigData-content">
          <div class="show-content">
            <p>对券商海量客户形成完善可依赖的用户画像体系，通过不同维度、不同类别的标签，全方位一体化刻画用户，保证精准性和覆盖度。基于标签体系的客户细分管理，自由定义客户细分群体，投其所好，有效解决业务中冷启动、画像稀疏等各类问题。</p>
            <p>客群分析主要实现功能:客户行为漏斗分析、客群账户分析、客群访问行为分析、客群交易分析、客群资产分析、两融客户分析等。</p>
          </div>
           <div class="show-img">
            <img src="../../assets/BigData/tab_BJ.png" alt="">
          </div>
        </div>
      </div>
      <!-- 运营分析 -->
      <div class="BigData-box" v-show ="TabId === 3">
        <div class="show_title">
          <img src="../../assets/BigData/shujufenxi-logo.png" alt="" />
          <p>运营分析</p>
        </div>
        <div class="BigData-content">
          <div class="show-content">
            <p>采用科学合理的方法，利用各类信息治理技术手段，对各类信息系统产生的数据进行分析，充分挖掘数据中蕴含的信息，并形成有用的运营分析指标数据。<br/>包括用户行为分析、运营实时分析、热卖销售分析等;</p>
          </div>
           <div class="show-img">
            <img src="../../assets/BigData/tab_BJ.png" alt="">
          </div>
        </div>
      </div>
      <!-- 精准营销 -->
      <div class="BigData-box" v-show ="TabId === 4">
        <div class="show_title">
          <img src="../../assets/BigData/shujujingzhun-logo.png" alt="" />
          <p>精准营销</p>
        </div>
        <div class="BigData-content">
          <div class="show-content">
            <p>制定营销活动，可配置广告的触发机制(如APP预设埋点)、广告内容、营销时间段触达渠道信息、目标客群。</p>
            <p>实时计算引擎，依据营销人员配置的触发机制，捕捉客户实时行为，可叠加基于标签筛选的目标客群分组，实时计算满足营销条件的客户，并推送至目标触达渠道，基于专家规则配置定义成功准则用于营销效果评估。</p>
            <p>营销活动展示，当前及历史营销活动列表，可查看方案详细内容。</p>
          </div>
           <div class="show-img">
            <img src="../../assets/BigData/tab_BJ.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- 选项卡 -->
    <div class="BigData-tab">
      <div class="tab-width" @mouseleave="tabLeave()" @mouseenter="tabSwitch()">
        <div
          class="tab-menu"
          :class="{ textChange: index == dynamic }"
          @click="check(index)"
          v-for="(item, index) in tabList"
          :key="index"
        >
          <div class="menu-content">
            <img :src="item.img" alt="" />
            <p class="menu-text">{{ item.tabtext }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- banner -->
    <div class="BigData-banner">
      <div class="banner-show">
        <!-- indicator-position="none" -->
        <el-carousel class="el-carousel" :autoplay="true" :interval="5000" height="570px" arrow="never" indicator-position="outside" >
          <el-carousel-item v-for="(item,index) in bannerList" :key="index">
            <img :src="item.img" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 底部 -->
    <div class="BigData-footer">
      <div class="footer-content">
        <div class="footer-left">
          <img src="../../assets/img/翔云logo.png" alt="" />
                 <p>地址 : 北京市丰台区南四环西路186号汉威国际广场三区1号楼6层604</p>
        </div>
        <div class="footer-right">
          <img src="../../assets/img/weixin.jpg" alt="" />
          <p>官方微信</p>
        </div>
      </div>
      <div class="footer-foot">
        版权所有 © 北京翔云在线数据技术有限公司
        网站备案/许可证号：京ICP备15021951号-2 京公网安备 11010802023249号
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false, // 控制导航栏上拉显示下拉隐藏
      top: "", // 获取top值
      TabId: 0,
      dynamic: 0,
      timer: null,
      tabList: [
        {
          img: require("../../assets/BigData/xitongpingtai.png"),
          tabtext: "平台架构及能力",
        },
        {
          img: require("../../assets/BigData/rizhi.png"),
          tabtext: "日志管理",
        },
        {
          img: require("../../assets/BigData/yonghuhuaxiang.png"),
          tabtext: "客户画像",
        },
        {
          img: require("../../assets/BigData/shujufenxi.png"),
          tabtext: "运营分析",
        },
        {
          img: require("../../assets/BigData/shujujingzhun.png"),
          tabtext: "精准营销",
        },
      ],
      bannerList: [
        {
          img: require("../../assets/BigData/banner_one.png"),
        },
        {
          img: require("../../assets/BigData/banner_two.png"),
        },
        // {
        //   img: require("../../assets/BigData/banner_one.png"),
        // },
        // {
        //   img: require("../../assets/BigData/banner_two.png"),
        // }
      ]
    };
  },
  created() {},
  mounted() {
    //获取浏览器滚动
    window.addEventListener("scroll", () => {
      this.top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
    });
    //tab栏自动循环
    this.timer = setInterval(() => {
      this.TabId += 1;
      this.dynamic += 1;
      if (this.TabId > 4) {
        this.TabId = 0; // 循环
      }
      //这里放当前数据
       if (this.dynamic > 4) {
        this.dynamic = 0; // 循环
      }
    }, 30000);
  },
  methods: {
    //将点击事件获取的下标ID传递给要切换的盒子
    check(index) {
      this.TabId = index;
      this.dynamic = index;
    },
    tabSwitch() {
      clearInterval(this.timer);
      this.timer = 0;
    },
    tabLeave() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.TabId += 1;
          this.dynamic += 1;
          if (this.TabId > 4) {
            this.TabId = 0; // 循环
          }
          //这里放当前数据
          if (this.dynamic > 4) {
            this.dynamic = 0; // 循环
          }
        }, 30000);
      }
    },
  },
   // 销毁定时器
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
  // 监听top值的变化
  watch: {
    top(newValue, oldValue) {
      // 等新值大于100的时候再做变化（优化一下）
      //   console.log(this.top);
      if (newValue < oldValue) {
        if (this.top <= 300) {
          this.isShow = false;
        } else {
          // 向上滚动
          this.isShow = true;
        }
      } else {
        // this.navShow = true
        this.isShow = false;
      }
    },
  },
  components: {},
};
</script>
<style scoped lang="scss">
@import "../../utlis/reset.css";
@import "../../utlis/navigation.css";
@import "BigData.css";
.BigData {
  .textChange {
    background: #4d4d4d;
  }
  ::v-deep {
    .el-carousel {
      width: 912px;
      position: absolute;
      top: 43px;
      left: 152px;
    }
    .el-carousel__button {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-top: 100px;
    }

    .el-carousel__indicator.is-active button {
      width: 30px;
      height: 20px;
      border-radius: 10px;
      background-color: #2A81C4;
    }
  }
  





}
</style>