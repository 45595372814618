<template>
 <div class="metaverse">
    <!--固定 头部 -->
    <div class="navigation-header navigation-header-absolute">
      <!-- logo -->
      <router-link to="/">
          <img src="../../assets/img/xyyylogo.png" alt="logo">
      </router-link>
      <!-- 导航栏 -->
      <nav class="navigation-nav">
        <ul>
          <li>
            <router-link to="BusinessHall" class="home-a">智慧营业厅</router-link>
          </li>
          <li>
            <router-link to="Metaverse" class="home-a home-text">元宇宙</router-link>
          </li>
          <li>
            <router-link to="BigData" class="home-a">大数据</router-link>
          </li>
          <li>
            <router-link to="InternetTerminal" class="home-a">互联网终端</router-link
            >
          </li>
          <li>
            <router-link to="AboutUs" class="home-a">关于翔云</router-link>
          </li>
        </ul>
      </nav>
    </div>
    <!--移动头部 -->
    <div
      class="navigation-header navigation-header-bg"
      :style="isShow ? `top:0px;opacity: 0.7;` : `top:-100px;opacity: 0.8;`"
    >
      <!-- logo -->
     <router-link to="/">
          <img src="../../assets/img/xyyylogo.png" alt="logo">
      </router-link>
      <!-- 导航栏 -->
      <nav class="navigation-nav">
        <ul>
          <li>
            <router-link to="BusinessHall" class="home-a">智慧营业厅</router-link>
          </li>
          <li>
            <router-link to="Metaverse" class="home-a home-text">元宇宙</router-link>
          </li>
          <li>
            <router-link to="BigData" class="home-a">大数据</router-link>
          </li>
          <li>
            <router-link to="InternetTerminal" class="home-a">互联网终端</router-link
            >
          </li>
          <li>
            <router-link to="AboutUs" class="home-a">关于翔云</router-link>
          </li>
        </ul>
      </nav>
    </div>
    <!-- 元宇宙大屏 -->
    <div class="metaverse-screen">
      <img src="../../assets/Metaverse/tt.png" alt="元宇宙">
      <div class="screen-text">
       <img src="../../assets/Metaverse/text.png" alt="">
       <!-- 遮罩层 -->
          <div class="metaverse-mask"></div>
        <p>致力于金融行业元宇宙虚拟营业厅的探索实践，针对行业中的各个业务应用场景，基于不断积累的大量用户以及业务数据，通过人工智能学习探索用户意识形态及数字业务形态，形成元宇宙的业务场景，如AI虚拟人视频、虚拟人实时播报等，逐步打造金融行业的元宇宙虚拟营业厅； </p>
      </div>
      <div class="metaverse-down">
        <img src="../../assets/Metaverse/YZ.png" alt="元宇宙拼接部分">
      </div>
    </div>
    <!-- 多样化的AI虚拟数字人类型 -->
    <div class="metaverse-fictitious">
        <h3>多样化的AI虚拟数字人类型</h3>
        <div class="metaverse-img">
            <img src="../../assets/Metaverse/kt.png" alt="kt" class="image-left">
            <img src="../../assets/Metaverse/3d.png" alt="3d" class="image-top">
            <img src="../../assets/Metaverse/fz.png" alt="fz" class="image-top">
            <img src="../../assets/Metaverse/jd.png" alt="jd" class="image-right">
        </div>
        <p>可制作3D仿真人物、动画人物等多样化的AI虚拟数字人类型，支持专业形象定制化服务，可私有化部署，满足不同客户的需求，打造企业自主品牌的AI虚拟人</p>
    </div>
    <!-- 丰富的AI虚拟人数字库 -->
    <div class="metaverse-number">
      <div class="number-left">
        <h3>丰富的AI虚拟人数字库</h3>
        <p>丰富的虚拟人面部表情库、形象库、动作库等，通过语音动作合成技术，驱动AI虚拟人说话、表情和动作协同，形成唇音同步、表情姿态动作同步；</p>
      </div>
      <div class="number-right">
        <img src="../../assets/Metaverse/hb.png" alt="">
      </div>
    </div>
    <!-- AI虚拟视频 | AI虚拟主播 -->
    <div class="metaverse-AI">
      <div class="AI-left">
        <img src="../../assets/Metaverse/zz.png" alt="">
      </div>
      <div class="AI-right">
        <h3>AI虚拟视频 | AI虚拟主播</h3>
        <p>支持制作AI虚拟视频，功能丰富且简单易操作，可快速的生成专业的AI虚拟人视频； <br/>证券行情或最新资讯等，支持通过文本或语音输入智能驱动AI虚拟人实时播报，便捷快速的进行AI虚拟主播；</p>
      </div>
    </div>
    <!-- 跨平台 -->
    <div class="metaverse-platform">
      <div>
        <h3>跨平台</h3>
        <p>AI虚拟人可跨平台使用和访问，支持SDK、API等接入方式，可应用在移动端、平板、PC、大屏等。</p>
      </div>
    </div>
    <!-- 底部 -->
    <div class="metaverse-footer">
      <div class="footer-content">
        <div class="footer-left">
          <img src="../../assets/img/翔云logo.png" alt="" />
                <p>地址 : 北京市丰台区南四环西路186号汉威国际广场三区1号楼6层604</p>
        </div>
        <div class="footer-right">
          <img src="../../assets/img/weixin.jpg" alt="" />
          <p>官方微信</p>
        </div>
      </div>
      <div class="footer-foot">
        版权所有 © 北京翔云在线数据技术有限公司
        网站备案/许可证号：京ICP备15021951号-2 京公网安备 11010802023249号
      </div>
    </div>
 </div>
</template>

<script>
export default {
    data(){
    return{
        isShow: false, // 控制导航栏上拉显示下拉隐藏
        top:'',  // 获取top值
    }
    },
    created(){},
    mounted() {
      //获取浏览器滚动
      window.addEventListener("scroll", () => {
        this.top =
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          window.pageYOffset;
      });
    },
    methods:{},
    // 监听top值的变化
    watch: {
        top(newValue, oldValue) {
        // 等新值大于100的时候再做变化（优化一下）
        console.log(this.top);
        if (newValue < oldValue) {
            if (this.top <= 300) {
            this.isShow = false;
            } else {
            // 向上滚动
            this.isShow = true;
            }
        } else {
            // this.navShow = true
            this.isShow = false;
        }
        },
    },
    components:{}
    }

</script>
<style scoped>
@import "../../utlis/reset.css";
@import "../../utlis/navigation.css";
@import "Metaverse.css";
</style>