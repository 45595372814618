<template>
  <div class="home">
    <!--固定 头部 -->
    <div class="navigation-header navigation-header-absolute">
      <!-- logo -->
      <img src="../../assets/img/xyyylogo.png" alt="" />
      <!-- 导航栏 -->
      <nav class="navigation-nav">
        <ul>
          <li>
            <router-link to="BusinessHall" class="home-a"
              >智慧营业厅</router-link
            >
          </li>
          <li>
            <router-link to="Metaverse" class="home-a">元宇宙</router-link>
          </li>
          <li>
            <router-link to="BigData" class="home-a">大数据</router-link>
          </li>
          <li>
            <router-link to="InternetTerminal" class="home-a"
              >互联网终端</router-link
            >
          </li>
          <li>
            <router-link to="AboutUs" class="home-a">关于翔云</router-link>
          </li>
        </ul>
      </nav>
    </div>
    <!--移动头部 -->
    <div
      class="navigation-header navigation-header-bg"
      :style="isShow ? `top:0px;opacity: 0.7;` : `top:-100px;opacity: 0.8;`"
    >
      <!-- logo -->
      <img src="../../assets/img/xyyylogo.png" alt="" />
      <!-- 导航栏 -->
      <nav class="navigation-nav">
        <ul>
          <li>
            <router-link to="BusinessHall" class="home-a"
              >智慧营业厅</router-link
            >
          </li>
          <li>
            <router-link to="Metaverse" class="home-a">元宇宙</router-link>
          </li>
          <li>
            <router-link to="BigData" class="home-a">大数据</router-link>
          </li>
          <li>
            <router-link to="InternetTerminal" class="home-a"
              >互联网终端</router-link
            >
          </li>
          <li>
            <router-link to="AboutUs" class="home-a">关于翔云</router-link>
          </li>
        </ul>
      </nav>
    </div>
    <!-- 视频轮播 -->
    <div class="home-video">
      <el-carousel
        :autoplay="false"
        arrow="never"
        :interval="interval"
        trigger="click"
        height="840px"
        class="el-carousel"
        @setActiveItem="setActiveItem"
        ref="carouselRef"
      >
        <el-carousel-item
          v-for="(item, index) in videolist"
          :key="index"
          name="index"
        >
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="item"
          >
          </video-player>
          <p class="home_title">{{item.text}}</p>
        </el-carousel-item>
      </el-carousel>
      <!-- <p class="home_title" v-for="(item,index) in textLsit" :key="index">{{item.text}}</p> -->
    </div>
    <!-- 我们得服务 -->
    <div class="home-service">
      <p>我们的服务</p>
    </div>
    <div class="home-function">
      <!-- 图1 -->
      <div
        class="function-one-box"
        @mouseenter="boxFun('oneBox', true)"
        @mouseleave="boxFun('oneBox', false)"
        :class="{ 'function-one-box-copy': oneBox }"
      >
        <div class="function-one">
          <!-- 遮罩层 -->
          <div class="mask"></div>
          <transition name="fade" mode="in-out">
            <div
              class="function-text"
              :class="{ 'function-text-hind': oneBox }"
            >
              <h3>智慧营业厅</h3>
              <p>智慧网点服务新模式</p>
              <img
                src="../../assets/img/arrow.png"
                alt=""
                class="function-more"
              />
            </div>
          </transition>
          <!-- 鼠标经过展示 -->
          <transition name="fade" mode="in-out">
            <div
              class="function-hover"
              v-if="hoverOneBox"
              :style="hoverOneBox ? 'display:block' : 'display:none'"
            >
              <router-link to="BusinessHall">
                <div class="hover-img">
                  <div class="img-text">
                    <img src="../../assets/img/businessHall_left.png" alt="" />
                    <!-- <p>AI虚拟人直播</p> -->
                  </div>
                  <div class="img-text">
                    <img src="../../assets/img/businessHall_right.png" alt="" />
                    <!-- <p>虚拟人定制</p> -->
                  </div>
                </div>
              </router-link>
              <div class="hover-text">
                <h1>智慧营业厅</h1>
                <!-- <p>探索金融业元宇宙应用场景</p> -->
                <p>
                  全天候7*24小时，并时刻保持良好的工作状态，远程智能管理，满足后疫情时代需求
                </p>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <!-- 图2 -->
      <div
        class="function-two-box"
        @mouseenter="boxFun('twoBox', true)"
        @mouseleave="boxFun('twoBox', false)"
        :class="{ 'function-two-box-copy': twoBox }"
      >
        <div class="function-two">
          <!-- 遮罩层 -->
          <div class="mask"></div>
          <div class="function-text" :class="{ 'function-text-hind': twoBox }">
            <h3>元宇宙虚拟营业厅</h3>
            <p>搜索金融业元宇宙应用场景</p>
            <img
              src="../../assets/img/arrow.png"
              alt=""
              class="function-more"
            />
          </div>
          <!-- 鼠标经过展示 -->
          <transition name="fade" mode="in-out">
            <div
              class="function-hover"
              v-if="hoverTwoBox"
              :style="hoverTwoBox ? 'display:block' : 'display:none'"
            >
              <router-link to="Metaverse">
                <div class="hover-img">
                  <div class="img-text">
                    <img src="../../assets/img/metaverse_left.png" alt="" />
                    <!-- <p>AI虚拟人直播</p> -->
                  </div>
                  <div class="img-text">
                    <img src="../../assets/img/metaverse_right.png" alt="" />
                    <!-- <p>虚拟人定制</p> -->
                  </div>
                </div>
              </router-link>
              <div class="hover-text">
                <h1>元宇宙</h1>
                <p>探索金融业元宇宙应用场景</p>
                <p>
                  AI虚拟人视频、虚拟人实时播报等，逐步打造金融行业的元宇宙虚拟营业厅
                </p>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <!-- 图3 -->
      <div
        class="function-three-box"
        @mouseenter="boxFun('threeBox', true)"
        @mouseleave="boxFun('threeBox', false)"
        :class="{ 'function-three-box-copy': threeBox }"
      >
        <div class="function-three">
          <!-- 遮罩层 -->
          <div class="mask"></div>
          <div
            class="function-text"
            :class="{ 'function-text-hind': threeBox }"
          >
            <h3>大数据平台</h3>
            <p>打造数据驱动型智慧金融</p>
            <img
              src="../../assets/img/arrow.png"
              alt=""
              class="function-more"
            />
          </div>
          <!-- 鼠标经过展示 -->
          <transition name="fade" mode="in-out">
            <div
              class="function-hover"
              v-if="hoverThreeBox"
              :style="hoverThreeBox ? 'display:block' : 'display:none'"
            >
              <router-link to="BigData">
                <div class="hover-img">
                  <div class="img-text">
                    <img src="../../assets/img/data_left.png" alt="" />
                    <!-- <p>AI虚拟人直播</p> -->
                  </div>
                  <div class="img-text">
                    <img src="../../assets/img/data_right.png" alt="" />
                    <!-- <p>虚拟人定制</p> -->
                  </div>
                </div>
              </router-link>
              <div class="hover-text">
                <h1>大数据平台</h1>
                <!-- <p>探索金融业元宇宙应用场景</p> -->
                <p>
                  覆盖客户全生命周期的数据，通过AI赋能，为金融行业降本增效，提高用户体验。
                </p>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <!-- 图4 -->
      <div
        class="function-four-box"
        @mouseenter="boxFun('fourBox', true)"
        @mouseleave="boxFun('fourBox', false)"
        :class="{ 'function-four-box-copy': fourBox }"
      >
        <div class="function-four">
          <!-- 遮罩层 -->
          <div class="mask"></div>
          <div class="function-text" :class="{ 'function-text-hind': fourBox }">
            <h3>互联网终端</h3>
            <p>助力金融业数字化进程</p>
            <img
              src="../../assets/img/arrow.png"
              alt=""
              class="function-more"
            />
          </div>
          <!-- 鼠标经过展示 -->
          <transition name="fade" mode="in-out">
            <div
              class="function-hover"
              v-if="hoverFourBox"
              :style="hoverFourBox ? 'display:block' : 'display:none'"
            >
              <router-link to="InternetTerminal">
                <div class="hover-img">
                  <div class="img-text">
                    <img src="../../assets/img/internetTerminal_left.png" alt="" />
                    <!-- <p>AI虚拟人直播</p> -->
                  </div>
                  <div class="img-text">
                    <img src="../../assets/img/internetTerminal_right.png" alt="" />
                    <!-- <p>虚拟人定制</p> -->
                  </div>
                </div>
              </router-link>
              <div class="hover-text">
                <h1>互联网终端</h1>
                <!-- <p>探索金融业元宇宙应用场景</p> -->
                <p>
                  带来更专业和更精细化的服务，使客户的业务办理和交易更加便捷、高效、安全合规。
                </p>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <!-- 核心优势 -->
    <div class="home-service">
      <p>核心优势</p>
    </div>
    <div class="home-textup">
      <div class="text-left" :style="homeTextup ? `left:137px` : ``">
        <h3>数据+AI+服务</h3>
        <p>
          覆盖客户全生命周期的数据，通过AI赋能，清算结算、风控管理、客户服务、资金托管、产品登记、信息披露等都可以在AI的支撑体系下完成，AI虚拟人视频或实时播报，满足金融行业各种业务场景需求，为金融行业降本增效，提高用户体验。
        </p>
      </div>
      <div class="img-right" :style="homeTextup ? `right:157px` : ``">
        <img src="../../assets/img/AI_right.png" alt="" />
      </div>
    </div>
    <div class="home-textdown">
      <div class="img-left" :style="homeTextdown ? `left:77px` : ``">
        <img src="../../assets/img/man-machine_left.png" alt="" />
      </div>
      <div class="text-right" :style="homeTextdown ? `right:161px` : ``">
        <h3>人智+机智</h3>
        <p>
          打造金融营业网点服务新模式，手机或PAD提前预约业务，机器人智能引导、VTM业务自助办理，全天候7*24小时，并时刻保持良好的工作状态，远程智能管理，满足后疫情时代需求，带来更专业和更精细化的服务，使客户的业务办理和交易更加便捷、高效、安全合规。
        </p>
      </div>
    </div>

    <!-- 客户案例 -->
    <div class="home-case">
      <h1>智能金融产品-助力金融机构实现业务增长</h1>
      <p>——— 客户案例 ———</p>
      <!-- 客户案例--选项卡 -->
      <div class="case-tab">
        <!-- 选项卡部分 -->
        <div class="tab" @mouseleave="tabLeave()" @mouseenter="tabSwitch()">
          <div
            class="tab-logo"
            v-for="(item, index) in tablist"
            :class="{ colorChange: index == dynamic }"
            :key="index"
            @click="check(index)"
          >
            <img :class="{ 'imgOne' : 1 === index}" :src="item.img" alt="" />
          </div>
        </div>
        <!-- 展示部分 -->
        <div
          class="tab-show"
          v-for="(item, index) in tablist"
          :key="index"
          v-show="index === TabId"
        >
          <div class="show-text">
            <h3>{{ item.title }}<span>{{item.subheading}}</span></h3>
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
      <!-- <div class="Indicator" >
        <div class="point" v-for="(item, index) in tablist" :key="index" :class="{ active: index == TabId }"></div>
      </div> -->
    </div>
    <!-- 合作伙伴 -->
    <div class="home-cooperation">
      <h1>在发展的路上 与众多优秀公司竭诚合作</h1>
      <p>——— 合作伙伴 ———</p>
      <div class="cooperation-box">
        <div class="cooperation-show">
          <div
            class="coooeration-item"
            v-for="(item, index) in cooperationlist"
            :key="index"
          >
            <img :class="{ 'imgFive' :  4 === index ,  'imgTen' :  9 === index}" :src="item.img" alt="合作伙伴" />
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <div class="home-footer">
      <div class="footer-content">
        <div class="footer-left">
          <img src="../../assets/img/翔云logo.png" alt="" />
                 <p>地址 : 北京市丰台区南四环西路186号汉威国际广场三区1号楼6层604</p>
        </div>
        <div class="footer-right">
          <img src="../../assets/img/weixin.jpg" alt="" />
          <p>官方微信</p>
        </div>
      </div>
      <div class="footer-foot">
        版权所有 © 北京翔云在线数据技术有限公司
        网站备案/许可证号：京ICP备15021951号-2 京公网安备 11010802023249号
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      setActiveItem:"",
      //vue-video-player 插件
      //视频轮播数组
      videolist: [
        {
          text: "翔云数据用科技助力金融行业",
          //playbackRates: [0.5, 1.0, 1.5, 2.0, 5.0], // 可选的播放速度
          autoplay: true, // 如果为true,浏览器准备好时开始回放。muted
          muted: true, // 默认情况下将会消除任何音频。
          loop: true, // 是否视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          //muted: "muted", //谷歌自动播放
          sources: [
            {
              type: "video/mp4",
              src: require("../../assets/video/baner1.mp4"),
            },
          ], // 因为我们要动态加载，所以src默认为空
          poster: "", // 封面地址预览图
          notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: false, // 当前时间和持续时间的分隔符
            durationDisplay: false, // 显示持续时间
            remainingTimeDisplay: false, // 是否显示剩余时间功能
            fullscreenToggle: false, // 是否显示全屏按钮
            volumeControl: false, // 声音控制键
            playToggle: false, // 暂停和播放键
            progressControl: false, // 进度条
          },
        },
        {
          text: "金融智能服务",
          //playbackRates: [0.5, 1.0, 1.5, 2.0, 5.0], // 可选的播放速度
          autoplay: true, // 如果为true,浏览器准备好时开始回放。muted
          muted: true, // 默认情况下将会消除任何音频。
          loop: true, // 是否视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          //muted: "muted", //谷歌自动播放
          sources: [
            {
              type: "video/mp4",
              src: require("../../assets/video/banner2.mp4"),
            },
          ], // 因为我们要动态加载，所以src默认为空
          // poster: require("../../assets/video/firstScreen.jpg"), // 封面地址
          poster: "", // 封面地址
          notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: false, // 当前时间和持续时间的分隔符
            durationDisplay: false, // 显示持续时间
            remainingTimeDisplay: false, // 是否显示剩余时间功能
            fullscreenToggle: false, // 是否显示全屏按钮
            volumeControl: false, // 声音控制键
            playToggle: false, // 暂停和播放键
            progressControl: false, // 进度条
          },
        },
      ],
      current: 0, //轮播视频的位置
      interval: 6000, //轮播图切换时间
      isShow: false, // 控制导航栏上拉显示下拉隐藏
      top: "", // 获取top值
      homeTextup: false,
      homeTextdown: false,
      // 选项卡
      TabId: 0, //默认0
      dynamic: 0,
      timer: null, // 定时器
      tablist: [
        {
          img: require("../../assets/anli/方正证券.png"),
          title: "方正证券VTM智慧柜员机",
          content:
            "作为方正证券VTM智慧柜员机应用软件开发商，与方正证券携手为客户开启全新的服务渠道，齐全的业务办理模块、办理流程简洁规范、智能身份识别、智能双录、全自助业务办理，为客户带来更加智能化的服务体验",
        },
        {
          img: require("../../assets/hezuo/景顺长城.png"),
          title: "景顺长城基金权益投研",
          content:
            "基于企业微信平台，实现权益投研企微端看研报服务、研报服务控制、Websocket推送等功能提升使用体验；非结构化数据自动解析、投研日历、研究分管等功能支持投研工作效率提升。通过API权限网关实现微服务API管理；基于低代码平台快速搭建应用",
        },
        {
          img: require("../../assets/anli/国泰君安.png"),
          title: "国泰君安智能引导台",
          content:
            "为国泰君安证券提供智能引导台的整体解决方案，智能客服互动问答、客户业务开通情况和资质自助查询、单据打印、让客户更加方便详细地了解公司相关的产品和业务，支持个人和机构客户，助力国泰君安证券“以客户为中心”的财富管理转型向高质量发展转变，促进国泰君安证券建设领先行业的一流智慧化证券网点",
        },
        {
          img: require("../../assets/anli/太平洋.png"),
          title: "太平洋证券太牛APP",
          content:
            "为太平洋证券打造的一站式综合交易理财平台，集市场行情分析和在线交易两大功能为一体，提供专业的资产分析功能，为用户推荐制定安全性较高的理财方案，交易动态实时查看，全市场极速行情、实时报价提醒等各项投资理财服务，同步推出大数据决策、稳赢理财、自选股云同步、人工智能、分享推荐等功能，使投资更简单，服务更贴心",
        },
        {
          img: require("../../assets/hezuo/中银.png"),
          title: "中银国际证券",
          subheading:"-微信生态解决方案",
          content: "为中银国际证券提供微信生态的整体解决方案，其中微信公众号服务平台包含前台客户服务功能（微信公众账号服务）和后台管理，主要实现银证扬帆活动、信息推送、素材管理、信息回复、统计等功能；微信商户平台接入微信支付，成为微信支付商户，完成业务的闭环；助力中银国际证券实现全渠道获客、联通各个业务场景实现精细化运营，形成标准化服务和规模效应",
        },
      ],
      cooperationlist: [
        { img: require("../../assets/hezuo/山西.png") },
        { img: require("../../assets/hezuo/爱建.png") },
        { img: require("../../assets/hezuo/太平洋证券.png") },
        { img: require("../../assets/hezuo/方正证券.png") },
        { img: require("../../assets/hezuo/中银.png") },
        { img: require("../../assets/hezuo/第一创业.png") },
        { img: require("../../assets/hezuo/国泰君安.png") },
        { img: require("../../assets/hezuo/信达证券.png") },
        { img: require("../../assets/hezuo/西部证券.png") },
        { img: require("../../assets/hezuo/景顺长城.png") },
      ],

      //控制function-text 显示/隐藏
      oneBox: false,
      twoBox: false,
      threeBox: false,
      fourBox: false,

      // 控制funcino-hover 显示/隐藏
      hoverOneBox: false,
      hoverTwoBox: false,
      hoverThreeBox: false,
      hoverFourBox: false,
    };
  },
  methods: {
    /**
     * 第四个盒子的进入事件
     */
    boxFun(type, flag) {
      if (flag) {
        this.oneBox = true;
        this.twoBox = true;
        this.threeBox = true;
        this.fourBox = true;
        if (type === "oneBox") {
          this.hoverOneBox = true;
        }
        if (type === "twoBox") {
          this.hoverTwoBox = true;
        }
        if (type === "threeBox") {
          this.hoverThreeBox = true;
        }
        if (type === "fourBox") {
          this.hoverFourBox = true;
        }
      } else {
        this.oneBox = false;
        this.twoBox = false;
        this.threeBox = false;
        this.fourBox = false;
        this.hoverOneBox = false;
        this.hoverTwoBox = false;
        this.hoverThreeBox = false;
        this.hoverFourBox = false;
      }
    },
    //将点击事件获取的下标ID传递给要切换的盒子
    check(index) {
      this.TabId = index;
      this.dynamic = index;
    },
    tabSwitch() {
      clearInterval(this.timer);
      this.timer = 0;
    },
    tabLeave() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.TabId += 1;
          //当前第五个为空，暂时在循环时候不显示，如有需要将 3 更改为 4 即可。
          if (this.TabId > 4) {
            this.TabId = 0; // 循环
          }
          //这里放当前数据
          if (this.dynamic > 4) {
            this.dynamic = 0; // 循环
          }
        }, 15000);
      }
    },
   
  },
  created() {
    this.current = 1;
  },
  mounted() {
    this.timer = setInterval(() => {
      this.TabId += 1;
      //当前第五个为空，暂时在循环时候不显示，如有需要将 3 更改为 4 即可。
      if (this.TabId > 3) {
        this.TabId = 0; // 循环
      }
      //这里放当前数据
      if (this.dynamic > 3) {
        this.dynamic = 0; // 循环
      }
    }, 15000);

    //获取浏览器滚动
    window.addEventListener("scroll", () => {
      this.top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;

        console.log(this.top);
    });
  },
  // 销毁定时器
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
  // 监听top值的变化
  watch: {
    top(newValue, oldValue) {
      // 等新值大于100的时候再做变化（优化一下）
      if (newValue < oldValue) {
        if (this.top <= 300) {
          this.isShow = false;
        } else {
          // 向上滚动
          this.isShow = true;
        }
      } else {
        // this.navShow = true
        this.isShow = false;

        if (this.top > 1300) {
          this.homeTextup = true;
        }

        if (this.top > 2000) {
          this.homeTextdown = true;
        }
      }
    },
    // 监听current值的变化
    current(value, oldValue) {
      console.log(value, "value");
      if (value === 0) {
        setTimeout(() => {
          this.current = 1;
          this.interval = 6000;
          this.$refs.carouselRef.setActiveItem(1);
        }, 14000);
      }
      if (value === 1) {
        setTimeout(() => {
          this.current = 0;
          this.interval = 20000;
          this.$refs.carouselRef.setActiveItem(0);
        }, 6000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../utlis/reset.css";
@import "../../utlis/navigation.css";
@import "HomeView.css";
.home {
  .function-text-hind {
    display: none;
  }
  ::v-deep {
    .vjs-big-play-button {
      display: none;
    }
    .html_player_enhance_tips {
      display: none !important;
    }
  }
  .el-carousel {
    ::v-deep {
      .el-carousel__container {
        .el-carousel__arrow--right,
        .el-carousel__arrow--left {
          display: none;
        }
      }
      .el-carousel__indicator {
        .el-carousel__button {
          // width: 130px;
          // height: 10px;
          // opacity: 0.5;
        }
        .is-active {
          // background-color: red;
        }
      }
    }
  }

  .fade-enter {
    opacity: 0;
  }
  .fade-leave {
    opacity: 1;
  }
  .fade-enter-active {
    transition: opacity 0.5s;
  }
  .fade-leave-active {
    opacity: 0;
    transition: opacity 0.5s;
  }
  .colorChange {
    background-color: #f4faff;
  }
  .imgFive {
    transform: scale(0.9,0.9);
  }
  .imgTen {
    transform: scale(0.8,0.8);
  }
  .imgOne {
    // margin-top: 5px;
    transform: scale(0.8,0.8);
    padding-top: 40px;
  }
  //banner动态轮播
  .active {
    width: 20px;
    height: 15px;
    border-radius: 15px;
    background-color: #2a81c4;
  }
}
</style>
