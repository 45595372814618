<template>
 <div class="InternetTerminal">
    <!--固定 头部 -->
    <div class="navigation-header navigation-header-absolute">
      <!-- logo -->
      <router-link to="/">
          <img src="../../assets/img/xyyylogo.png" alt="logo">
      </router-link>
      <!-- 导航栏 -->
      <nav class="navigation-nav">
        <ul>
          <li>
            <router-link to="BusinessHall" class="home-a">智慧营业厅</router-link>
          </li>
          <li>
            <router-link to="Metaverse" class="home-a">元宇宙</router-link>
          </li>
          <li>
            <router-link to="BigData" class="home-a">大数据</router-link>
          </li>
          <li>
            <router-link to="InternetTerminal" class="home-a home-text">互联网终端</router-link
            >
          </li>
          <li>
            <router-link to="AboutUs" class="home-a">关于翔云</router-link>
          </li>
        </ul>
      </nav>
    </div>
    <!--移动头部 -->
    <div
      class="navigation-header navigation-header-bg"
      :style="isShow ? `top:0px;opacity: 0.7;` : `top:-100px;opacity: 0.8;`"
    >
      <!-- logo -->
     <router-link to="/">
          <img src="../../assets/img/xyyylogo.png" alt="logo">
      </router-link>
      <!-- 导航栏 -->
      <nav class="navigation-nav">
        <ul>
          <li>
            <router-link to="BusinessHall" class="home-a">智慧营业厅</router-link>
          </li>
          <li>
            <router-link to="Metaverse" class="home-a">元宇宙</router-link>
          </li>
          <li>
            <router-link to="BigData" class="home-a">大数据</router-link>
          </li>
          <li>
            <router-link to="InternetTerminal" class="home-a home-text">互联网终端</router-link
            >
          </li>
          <li>
            <router-link to="AboutUs" class="home-a">关于翔云</router-link>
          </li>
        </ul>
      </nav>
    </div>
    <!-- 大屏显示 -->
    <div class="InternetTerminal-screen">
      <img src="../../assets/Terminal/terminal_BJ.png" alt="" />
      <div class="screen-text">
        <img src="../../assets/Terminal/terminal_title.png" alt="" />
        <p>
          打造一站式综合交易、理财、资讯平台，通过不同形态的互联网终端应用，依托覆盖客户全生命周期的大数据，提供市场行情分析、在线交易、投顾、推荐分享、线上活动以及专业的资产分析等功能。
        </p>
      </div>
    </div>
    <!-- 全平台原生应用 -->
    <div class="InternetTerminal-app">
      <h1>全平台原生应用</h1>
      <div class="app-up">
        <div class="content-show" v-for="(item,index) in upList" :key="index" @mouseenter="imgBig(index,1)" @mouseleave="imgSmall(index,1)" >
          <img :src="item.img" alt="" ref="imgChange">
          <h3>{{item.upTitle}}</h3>
          <div class="show-p"></div>
          <p>{{item.uptext}}</p>
          <p>{{item.upSecond}}</p>
          <p class="terminal-margin">{{item.upThird}}</p>
        </div>
      </div>
      <div class="app-down">
        <div class="content-show" v-for="(item,index) in downList" :key="index" @mouseenter="imgBig(index,2)" @mouseleave="imgSmall(index,2)" >
          <img :src="item.img" alt="" ref="imgChange">
          <h3>{{item.downTitle}}</h3>
          <div class="show-p"></div>
          <p>{{item.downtext}}</p>
        </div>
      </div>
      <div class="app-img">
        <img src="../../assets/Terminal/app_img.png" alt="">
        <div class="app_text">
          <h3>原生应用</h3>
          <p>全平台操作系统、硬件终端</p>
        </div>
        <div class="weixin_text">
          <h3>微信生态</h3>
          <p>公众号 | 小程序 | 企业微信</p>
        </div>
      </div>
    </div>
    <!-- 微信生态圈 -->
    <div class="InternetTerminal_Ecosphere">
      <h1>微信生态圈</h1>
      <div class="Ecosphere_box">
        <div class="Ecosphere_left">
          <div class="left_text">
            <h3>1、支持定制化开发</h3>
            <p>作为微信生态服务商，支持定制化开发微信生态应用，助力企业高效协作工作和实现数字化转型；</p>
          </div>
          <div class="left_text">
            <h3>2、搭建新的商业体系</h3>
            <p>搭建金融行业新的商业体系，直接在微信生态体系内完成商业模式的闭环；</p>
          </div>
          <div class="left_text">
            <h3>3、开发成本低</h3>
            <p>不需要考虑兼容性，开发成本大大降低；</p>
          </div>
          <div class="left_text">
            <h3>4、营销方式更加丰富 </h3>
            <p>小程序母体是微信，可以借助微信平台获取更多的用户及流量；</p>
          </div>
        </div>
        <div class="Ecosphere_right">
          <img src="../../assets/Terminal/Ecosphere_right.png" alt="">
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="InternetTerminal-footer">
      <div class="footer-content">
        <div class="footer-left">
          <img src="../../assets/img/翔云logo.png" alt="" />
               <p>地址 : 北京市丰台区南四环西路186号汉威国际广场三区1号楼6层604</p>
        </div>
        <div class="footer-right">
          <img src="../../assets/img/weixin.jpg" alt="" />
          <p>官方微信</p>
        </div>
      </div>
      <div class="footer-foot">
        版权所有 © 北京翔云在线数据技术有限公司
        网站备案/许可证号：京ICP备15021951号-2 京公网安备 11010802023249号
      </div>
    </div>
 </div>
</template>

<script>
export default {
    data(){
    return{
        isShow: false, // 控制导航栏上拉显示下拉隐藏
        top:'',  // 获取top值
        upList: [
          {
            img: require("../../assets/Terminal/App_one.png"),
            upTitle: "支持全品种交易",
            uptext:'一站式交易提供融资融券、科创板、创业板、新三板；',
            upSecond:'国债逆回购、可转债、港股通（沪深）等全品种交易服务；',
            upThird:'支持新股和新债打新；',
          },
          {
            img: require("../../assets/Terminal/App_two.png"),
            upTitle: "精选投资资讯",
            uptext:'全球7×24小时股市专业资讯服务，市场要闻、滚动播报、热点信息实时推送。专业研报精准推送，为客户量身打造投资良策；',
            upSecond:'精选推荐、实时资讯和投资日历，助客户未雨绸缪，把握市场先机。',
          },
          {
            img: require("../../assets/Terminal/App_three.png"),
            upTitle: "响应速度快",
            uptext:'全面准确快速的行情数据，覆盖国内、港股市场的实时行情数据，助客户抢先一步完成决策。',
            upSecond:'闪电下单，准确快速的行情展示，资讯、研报实时精准推送。',
          },
        ],
        downList: [
          {
            img: require("../../assets/Terminal/App_four.png"),
            downTitle: "全景账户",
            downtext:'通过股市月账单、股市回忆录、客户画像、账户分析为客户打造专属的360°全景账户；',
          },
          {
            img: require("../../assets/Terminal/App_five.png"),
            downTitle: "理财商城",
            downtext:'热门产品专区、主题理财专区和定投基金专区、理财日历等丰富多元化的理财品类，合理配置投资资源，为客户提供丰富的理财选择；',
          },
          {
            img: require("../../assets/Terminal/App_six.png"),
            downTitle: "优质体验",
            downtext:'界面简洁清晰，同时不断的优化完善交易流程和用户操作体验，使之更加符合用户的操作习惯；',
          }
        ]
    }
    },
    created(){},
    mounted() {
      //获取浏览器滚动
      window.addEventListener("scroll", () => {
        this.top =
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          window.pageYOffset;
      });
    },
    methods:{
      imgBig(index,num){
        if(num != 1){
          index += 3;
        }
        let getImg = this.$refs.imgChange[index];
        getImg.style.transform = "scale(1.1,1.1)";

      },
      imgSmall(index,num){
        if(num != 1){
          index += 3;
        }
        let getImg = this.$refs.imgChange[index];
        getImg.style.transform = "scale(1,1)";
      }
    },
    // 监听top值的变化
    watch: {
        top(newValue, oldValue) {
        // 等新值大于100的时候再做变化（优化一下）
        // console.log(this.top);
        if (newValue < oldValue) {
            if (this.top <= 300) {
            this.isShow = false;
            } else {
            // 向上滚动
            this.isShow = true;
            }
        } else {
            // this.navShow = true
            this.isShow = false;
        }
        },
    },
    components:{}
    }

</script>
<style scoped>
@import "../../utlis/reset.css";
@import "../../utlis/navigation.css";
@import "InternetTerminal.css";
</style>