import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/main/HomeView'
import BusinessHall from '../components/BusinessHall/BusinessHall'
import Metaverse from '../components/Metaverse/Metaverse'
import BigData from '../components/BigData/BigData'
import InternetTerminal from '../components/InternetTerminal/InternetTerminal'
import AboutUs from '../components/AboutUs/AboutUs'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    
  },
  {
    path: '/BusinessHall',
    name: 'BusinessHall',
    // component: BusinessHall,
    component: () => import('../components/BusinessHall/BusinessHall.vue')
  },
  {
    path: '/Metaverse',
    name: 'Metaverse',
    // component: Metaverse,
    component: () => import('../components/Metaverse/Metaverse.vue')
  },
  {
    path: '/BigData',
    name: 'BigData',
    // component: BigData,
    component: () => import('../components/BigData/BigData.vue')
  },
  {
    path: '/InternetTerminal',
    name: 'InternetTerminal',
    // component: InternetTerminal,
    component: () => import('../components/InternetTerminal/InternetTerminal.vue')
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    // component: AboutUs,
    component: () => import('../components/AboutUs/AboutUs.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
