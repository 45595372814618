<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view />
  </div>
</template>
<script>
import DevicePixelRatio from '../src/utlis/devicePixelRatio'
export default {
  name: 'App',
  data() {
    return {
    }
  },
  created() {
    new DevicePixelRatio().init()
  },
  beforeCreate() {
    let count = 0;
    let imgs = [
    //用require的方式添加图片地址，直接添加图片地址的话，在build打包之后会查找不到图片，因为打包之后的图片名称会有一个加密的字符串
        require('../src/assets/Metaverse/tt.png'),
        require('../src/assets/aboutUs/banner.png'),
        require('../src/assets/BigData/BigData.png'),
        require('../src/assets/BusinessHall/tt.png'),
        require('../src/assets/Terminal/terminal_BJ.png'),
    ]
    for (let img of imgs) {
        let image = new Image();
        image.onload = () => {
            count++;
        };
        image.src = img;
    }
  },
};
</script>

<style>
</style>
